.mantineTable table {
  border: 1px solid var(--mantine-color-gray-3);
}
.mantineTable .mantine-datatable-header-cell-sortable-text{
  color: var(---app-textblack100-color);
}
.mantineTable thead{
  background: #F9FAFB;
}
.mantineTable .mantine-datatable-nowrap {
  white-space: normal;
}
main {
  /* margin-left: 200px; */
  /* padding-top: 70px; */
  /* margin-right: 5px; */
}
.border-radius16 {
  border-radius: 16px !important;
}

/* reportTypeList */
.reportType-list header h2 {
  font-weight: 700;
}
.reportType-list .addreport-select label {
  color: var(---app-textlightblack-color);
}
.reporting-view .commoncolumns .mantine-Checkbox-input:checked {
  background-color: var(---app-bgLightBlue-color);
  border-color: var(---app-primaryBlue-color);
}

.reporting-view .commoncolumnsRightside .mantine-Checkbox-input:checked {
  background-color: var(---app-primaryBlue-color);
  border-color: var(---app-primaryBlue-color);
}

.reporting-view .commoncolumns .mantine-Checkbox-icon {
  color: var(---app-primaryBlue-color);
}
.reporting-view .commoncolumns label {
  color: var(---app-textlightblack-color);
}
.reporting-view .commoncolumnsGroupings label {
  color: var(---app-textGroup-color);
  font-size: 16px;
}

/* DataManagement */
.datamanagement .mantine-Tabs-tabLabel {
  color: var(---app-darkGrey-color);
  font-weight: 600;
}
.datamanagement
  .mantine-Tabs-list
  button[data-active="true"]
  .mantine-Tabs-tabLabel {
  color: var(---app-darkBlue-color);
  font-weight: 600;
}
.uploadmodal .mantine-Paper-root {
  border-radius: 16px;
}

/* configurations */
.configurations .mantine-Tabs-list {
  width: 18%;
  gap: 10px;
  padding-top: 20px;
}
.configurations .mantine-Tabs-panel {
  width: 0px;
  overflow: auto;
}
.configurations .mantine-Tabs-tabLabel {
  color: var(---app-darkGrey-color);
  font-weight: 600;
}
.configurations
  .mantine-Tabs-list
  button[data-active="true"]
  .mantine-Tabs-tabLabel {
  color: var(---app-textBlock-color);
  font-weight: 600;
}

.configuration-filedMapping .input input {
  color: var(---app-textBlock-color);
  font-size: var(---font-size-fit);
}
.configuration-filedMapping thead > tr > th {
  min-width: 150px;
}
/* CampaignConfig */
.campaignConfig-configTab .input label {
  font-size: 14px;
  color: var(---app-textlightblack-color);
}

.campaignConfig-configTab .input input {
  min-height: 40px;
  width: 40%;
  border-color: var(---app-textGrey-color);
}

.ant-tabs-tabpane {
  padding-left: 0px !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(---app-textBlock-color) !important;
  font-size: var(---font-size-medium) !important;
  font-weight: 600;
}
.ant-tabs-tab .ant-tabs-tab-btn {
  color: var(---app-textGroup-color) !important;
  font-size: var(---font-size-medium) !important;
  font-weight: 600;
}

/* View reports Table */
.viewTablereports thead > tr > th {
  /* white-space: pre; */
  min-width: 100px;
}


/* industryFormulae */

.industryFormulae{
  padding: 0 !important;
}