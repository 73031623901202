body {
  margin: 0;
  font-family: "Assistant", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f0f6ff !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer mantine, mantine-datatable;

:root {
  ---app-blue-color: #18a2da;
  ---app-primaryBlue-color: #0086c9;
  ---app-textWhite-color: #ffffff;
  ---app-textBlock-color: #101828;
  ---app-textlightblack-color: #344054;
  ---app-textblack100-color: #475467;
  ---app-darkBlue-color: #026aa2;
  ---app-darkGrey-color: #667085;
  ---app-lightBlue-color: #b9e6fe;
  ---app-bgGreyWhite-color: #fcfcfd;
  ---app-bgLightBlue-color: #f0f9ff;
  ---app-textGroup-color: #344054;
  ---app-textGrey-color: #d0d5dd;
  ---app-black-color: #000000;
  ---app-border-color:#EAECF0;

  /* font Size */
  ---font-size-medium: 16px;
  ---font-size-fit: 14px;
}

@media (max-width: 1536px) {
  :root {
    /* font Size */
    ---font-size-medium: 14px;
    ---font-size-fit: 12px;
  }
}
